import React from "react";
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const StyledLink = styled.a`
  ${fonts.montserratBold};
  padding: ${props => props.small ? '12px 20px' : '16px 48px'};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 12px;
  line-height: 16px;

  color: ${colors.black};
  background: ${colors.white};
  border: 1px solid ${colors.greyDark};
  
  &:hover {
    background: ${colors.green};
    color: ${colors.white};
    border-color: ${colors.green};
  }
`;

const ExternalLinkButtonWhite = ({href, className, children, small}) => {
  return (
    <StyledLink href={href}
       small={small}
       className={className}
       target="_blank"
       rel="noopener noreferrer">
      {children}
    </StyledLink>
  );
};

export default ExternalLinkButtonWhite;
