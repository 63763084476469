import React from "react";
import {Col, Row} from "styled-bootstrap-grid";
import styled from "styled-components";
import Img from "gatsby-image";
import breakpoints from "../../styles/breakpoints";
import TextCopy from "./TextCopy";
import ExternalLinkButton from "../Core/ExternalLinkButton";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const UspContainer = styled.div`
  text-align: center;
  margin-bottom: 100px;
  
  @media(min-width: ${breakpoints.md}){
    margin-bottom: 150px;
    margin-left: -12px;
    margin-right: -12px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: -10px;
  
  a {
    width: 100%;
  }
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 0;
    max-width: unset;
    
    a {
      max-width: 192px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const UspBlock = styled.div`
  padding-bottom: 40px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  
  @media(min-width: ${breakpoints.md}){
    max-width: unset;
    padding-bottom: 30px;
    margin-right: 12px;
    margin-left: 12px;
  }
`;

const ImgStyled = styled(Img)`
  border-radius: 10px;
  max-width: 360px;
  margin: auto;
`;

const UspContent = styled.div`
  max-width: 360px;
  margin: auto;
`;

const Usp = ({block}) => {
  return (
    <UspContainer>
      <Row>
        {block.map((element, index) => {
          return (
            <Col md={4} key={index}>
              <UspBlock>
                <ImgStyled fluid={element.image.fluid} alt={element.image.title}/>
                <UspContent>
                  <TextCopy>{documentToReactComponents(JSON.parse(element.text.raw))}</TextCopy>
                </UspContent>
              </UspBlock>
            </Col>
          )
        })}
        <ButtonContainer>
          <ExternalLinkButton href={process.env.APP_URL}>Get Started</ExternalLinkButton>
        </ButtonContainer>
      </Row>
    </UspContainer>
  )
};

export default Usp;