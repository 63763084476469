import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";

const Content = styled.div`
  p {
      font-size: 16px;
      line-height: 26px;
      ${fonts.montserratRegular};
      color: ${colors.blackDark};
  }
  
  h2 {
      font-size: 18px;
      line-height: 22px;
      ${fonts.montserratBold};
      margin-top: 18px;
      margin-bottom: 14px;
      color: ${colors.blackDark};
  } 
  
  @media(min-width: ${breakpoints.md}){
    p {
      font-size: 20px;
      line-height: 30px;
    }
    
    h2 {
        margin-top: 20px;
        margin-bottom: 16px;
    }
  }
`;

const TextCopy = ({children}) => {
  return (
    <Content>{children}</Content>
  )
};

export default TextCopy;