import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Core/Seo";
import Hero from "../components/Home/Hero";
import Intro from "../components/Home/Intro";
import Usp from "../components/Home/Usp";

const IndexPage = ({location, data}) => {
  const path = location.path;
  const page = data.page;

  return (
    <Layout>
      <SEO title={page.metaTitle}
           description={page.metaDescription}
           fullTitle={true}
           path={path}/>
      <Hero title={page.heroTitle} content={page.heroContent.raw} image={page.heroImage}/>
      <Intro title={page.introTitle} content={page.introContent.raw}/>
      <Usp block={page.uspBlock}/>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    page: contentfulHomepage {
      metaTitle
      metaDescription
      heroTitle
      heroContent {
        raw
      }
      heroImage {
        title
        fluid(
          maxWidth: 1110
          quality: 80
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      introTitle
      introContent {
        raw
      }
      uspBlock {
        text {
          raw
        }
        image {
          title
          fluid(
            maxWidth: 720
            quality: 80
          ) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;