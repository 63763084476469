import React from "react";
import styled from "styled-components";
import {Row, Col} from "styled-bootstrap-grid";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import TextCopy from "./TextCopy";
import Img from "gatsby-image";
// import LinkButtonWhite from "../Core/LinkButtonWhite";
// import ExternalLinkButton from "../Core/ExternalLinkButton";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ExternalLinkButtonWhite from "../Core/ExternalLinkButtonWhite";

const HeroContainer = styled.div`
  padding-top: 31px;
  margin-bottom: 40px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  
  @media(min-width: ${breakpoints.md}){
    padding-top: 0;
    max-width: unset;
    margin-bottom: 86px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  line-height: 40px;
  ${fonts.montserratBold};
  text-align: center;
  margin-bottom: 30px;
  
  br {
    display: none;
  }
  
  @media(min-width: ${breakpoints.md}){
    font-size: 40px;
    line-height: 54px;
    text-align: left;
    
    br {
      display: block;
    }
  }
`;

const Content = styled.div`
  text-align: center;
  
  @media(min-width: ${breakpoints.md}){
    text-align: left;
    max-width: 456px;
  }
`;

const HeroContentBlock = styled.div`
  @media(min-width: ${breakpoints.md}){
    padding-right: 12px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 22px;
  
  a {
    margin-top: 20px;
  }
  
  @media(min-width: ${breakpoints.md}){
    flex-direction: row;
    margin-bottom: 0;
    
    a {
      max-width: 192px;
      text-align: center;
      width: 100%;
      margin-right: 20px;
    }
  }
`;

const ImageBlock = styled.div`
  display: block;
  margin: 12px auto 0 auto;
  
  @media(min-width: ${breakpoints.md}) {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: unset;
    margin-right: unset;
  }
`;

const ImgStyled = styled(Img)`
  height: 100%;
  width: 100%;
  max-height: 200px;
  max-width: 550px;
  margin: auto;

  img {
    object-fit: contain !important;
  }
`;

const Hero = ({title, content, image}) => {

  return (
    <HeroContainer>
      <Row>
        <Col md={7} lg={5} xl={5}>
          <Content>
            <HeroContentBlock>
              <Title>{title}</Title>
              <TextCopy>{documentToReactComponents(JSON.parse(content))}</TextCopy>
            </HeroContentBlock>
            <Buttons>
              <ExternalLinkButtonWhite href={process.env.APP_URL}>Get Started</ExternalLinkButtonWhite>
              {/*<LinkButtonWhite to={"/documentation"}>Documentation</LinkButtonWhite>*/}
            </Buttons>
          </Content>
        </Col>
        <Col md={5} xl={5} lgOffset={1}>
          <ImageBlock>
            <ImgStyled fluid={image.fluid} alt={image.title}/>
          </ImageBlock>
        </Col>
      </Row>
    </HeroContainer>
  )
};

export default Hero;