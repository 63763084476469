import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import TextCopy from "./TextCopy";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const IntroContainer = styled.div`
  text-align: center;
  max-width: 360px;
  margin: 40px auto 60px auto;
  
  @media(min-width: ${breakpoints.md}){
    margin: 86px auto 80px auto;
    max-width: 552px;
    
    p {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 34px;
  ${fonts.montserratBold};
  margin-bottom: 20px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 30px;
    line-height: 44px;
  }
`;

const Intro = ({ title, content }) => {
  return (
    <IntroContainer>
      <Title>{title}</Title>
      <TextCopy>{documentToReactComponents(JSON.parse(content))}</TextCopy>
    </IntroContainer>
  )
};

export default Intro;